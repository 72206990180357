import React from 'react';
import hocify from 'hocify';
import { ToastProvider, useToasts } from 'react-toast-notifications'

function withToast(Component) {
    return function WrappedComponent(props) {
      const toastFuncs = useToasts()
      return <Component {...props} {...toastFuncs} />;
    }
  }
  
  //In you class component file
  
  class ToastNotifiction extends React.Component {
  
    componentDidMount () {
        console.log('ToastNotifiction::componentDidMount');
      this.props.addToast('Hello Toast');
    }
    
    render () {
      return <div>Toasts</div>
    }
  }
  
  export default withToast(ToastNotifiction);
