import * as React from 'react';
import {Carousel, Image} from 'react-bootstrap';
import firstImg from './../assets/InternalPagesBanner_12-1800x250.jpg';
import secondImg from './../assets/023_20_TWI-Scrolling-Banner_Nominate-Now.jpg';
import thirdImg from './../assets/Literacy-Action-Plan-1800x500.png';
import fourthImg from './../assets/Get-Involved-1800x500.png';

export default class ImgCarousel extends React.Component<any, any>{

    public render(): JSX.Element {
        return (
          <div id="page-banner" 
            style={{
              backgroundImage:`url(${firstImg})`,
              height:100,
              backgroundPosition:'centre top', 
              backgroundSize:'cover'
            }}>
          </div>
  
 /*<Carousel>
  <Carousel.Item>
    <img
      style={{height:100, backgroundPosition:'centre top', backgroundSize:'cover'}}
      className="d-block w-100"
      src={firstImg}
      alt="First slide"
    />
  </Carousel.Item>
   <Carousel.Item>
    <img
      className="d-block w-100"
      src={secondImg}
      alt="Third slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={thirdImg}
      alt="Third slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={fourthImg}
      alt="Fourth slide"
    />
  </Carousel.Item> 
</Carousel>*/
    
    );
        }

      }