import React from 'react';
import {Card, CardDeck, Container} from 'react-bootstrap';
import { Stack, IStackTokens } from 'office-ui-fabric-react/lib/Stack';
import SearchNavBox from './SearchNavBox';
import css from './../styles/Home.module.scss';
import { Grid, Image, Label, Item } from 'semantic-ui-react'
import {Link} from 'react-router-dom';
import sampleImg  from './../assets/Home-Page_Policies-and-Procedures-140px.png';
import 'semantic-ui-css/semantic.min.css'
import {DocumentSearch} from './DocumentSearch';
import {ViewToggle} from './ViewToggle';


import policiesIcon from "./../assets/Icons_Public_Document_Centre_Policies.png";
import formsIcon from "./../assets/Icons_Public_Document_Centre_Forms.png";
import IntakeAreasIcon from "./../assets/Icons_Public_Document_Centre_Intake_Areas.png";
import SchoolAssociationsIcon from "./../assets/Icons_Public_Document_Centre_School_Association.png";

const stackTokens: IStackTokens = { childrenGap: 20 };

export class Home extends React.Component<any, any>{

    constructor(props:any){
        super(props);

        this.state = {
            globalSearchActive:false,
            searchView:'PARENT_SEARCH',
            header:"Browse Documents",
            activeView:'HOME',
            publicLibraries:[
                {
                    "Name":"Policies", 
                    "Description": `Governance and operational policies, procedures and guidelines for Tasmanian government schools.`,
                    "Path":"Policies",
                    "Icon":{policiesIcon}.policiesIcon
                },  
                {
                    "Name":"Forms",
                    "Description": `A range of forms available for parents and carers of students in Tasmanian government schools.`,
                    "Path":"Forms",
                    "Icon":{formsIcon}.formsIcon
                }, 
                {
                    "Name":"School Association Constitutions",
                    "Description": `The constitution for School Associations for every Tasmanian school.`,
                    "Path":"SchoolAssocCons",
                    "Icon":{SchoolAssociationsIcon}.SchoolAssociationsIcon
                },
                {
                    "Name":"School Intake Area Maps", 
                    "Description": `Government primary and combined (district) school intake areas for students commencing from 2021.`,
                    "Path":"SchoolIntakeAreas",
                    "Icon":{IntakeAreasIcon}.IntakeAreasIcon
                }
            ]
            }
    }

    render(){

        let content = null;

        if(this.state.globalSearchActive)
        {
            console.log(this.state.searchView);
            console.log(this.state.searchFilter);

            content = <DocumentSearch 
                    source = 'All'
                    searchView={this.state.searchView}
                    searchFilter={this.state.searchFilter}
                    onSearchResults={(result:any) => {this.OnSearchResults(result)}}
                    />
        }
        else
        {
            
            content = <Grid columns={2} divided>                
                <Grid.Row>
                    {this.state.publicLibraries.map((lib: any, i: string) => {
                        
                        console.log(lib.Icon);

                        return <Grid.Column className={css.column} key={i}>
                            <Item.Group>
                            <Item className={css.item}>
                                <Item.Image size='tiny' src={lib.Icon} alt={lib.Name} />
                                <Item.Content>
                                    <Item.Header className={css.header}>
                                        {/* <a onClick={(e:React.MouseEvent)=>{this.OnPublicLibrarySelected(e)}}>{lib.Name}</a> */}
                                        <h2><Link to={'/'+lib.Path}>{lib.Name}</Link></h2>
                                    </Item.Header>
                                    <Item.Description className={css.description}>
                                    <p>{lib.Description}</p>
                                    </Item.Description>
                                </Item.Content>
                            </Item>
                            </Item.Group>
                        </Grid.Column>
        })}
                </Grid.Row>
            </Grid>
        }

        return(
            <Stack tokens={stackTokens}>
                <SearchNavBox onSearch={(searchTerm:string) => {this.OnGlobalSearch(searchTerm)}}/>      
                <Container>
                    <ViewToggle 
                        activeBtn={this.state.activeView}
                        header={this.state.header}
                        onViewChange={(view:string) => {this.handleViewChange(view)}}
                        showBtnToolbar={true}
                        toolbarButtons={[
                            {"Name":"Home", "Id":"HOME"},
                            // {"Name":"VIEW BY TOPIC","Visible":false},
                            // {"Name":"VIEW A-Z","Visible":false},
                        ]}/>   
                    {content}
                </Container>
            </Stack>
        );                
    }

    private OnGlobalSearch(searchTerm:string){
        this.setState({
            globalSearchActive:true,
            searchFilter:searchTerm, 
            activeView:'PARENT_SEARCH',  
            header:`Searching for ${searchTerm}`,         
        })
    }

    private OnSearchResults(results:any){
        this.setState({
            globalSearchActive:true,
            activeView:'PARENT_SEARCH',  
            header:`Displaying ${results.count} results matching ${results.searchTerm}`,         
        })
    }

    handleViewChange(view:string){
        console.log(`Forms::handleViewChange::triggered::${view}`);

        this.setState({
            viewChanged:true,
            activeView:'HOME',
            source:'All',
            //activeBtn:"HOME",
            header:"Browse Documents",
            searchFilter:'',
            globalSearchActive:false
        });
    }
    private OnPublicLibrarySelected(e:React.MouseEvent){
        //this.props.onViewChange(e.currentTarget.innerHTML);
        console.log(`Home::OnPublicLibrarySelected::triggered ${e.currentTarget.innerHTML}`);
        //<link
    }
}