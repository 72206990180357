import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import {Footer} from './components/Footer';
import * as serviceWorker from './registerServiceWorker';
// import your fontawesome library
import './fontawesome';
import { ToastProvider } from 'react-toast-notifications'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <ToastProvider><App /></ToastProvider>
    
  </BrowserRouter>,
  rootElement);

  serviceWorker.unregister();

