import React from 'react';
import SearchNavBox from './SearchNavBox';
import {ViewToggle} from './ViewToggle';
import { Topics } from './Forms/Topics';
//import { SearchResult } from './SearchResult';
import {Container} from 'react-bootstrap';
import { Stack, IStackTokens } from 'office-ui-fabric-react/lib/Stack';

import {DocumentSearch} from './DocumentSearch';

import { Link, Redirect } from 'react-router-dom';

const stackTokens: IStackTokens = { childrenGap: 20 };
const DocumentTypeName: string = 'Forms';
const SPOContentTypeName: string = 'DoE Forms';

export class Forms extends React.Component<any, any>{

    constructor(props:any){
        super(props);

        this.state = {
            activeView:"TOPICS",
            activeBtn:"VIEW BY TOPIC",
            header:"Browse Forms",
            formTopics:[
                {
                    "Name":"Student Enrolment and Attendance", 
                    "Description": `Enrolment and attendance of students, including admission, levies and fees, the Student Assistance Scheme (STAS), absences, participation, intake areas, student transfers and non-compulsory education. `
                }, 
                {
                    "Name":"Student ​Facilities and Assets", 
                    "Description": `Department for Education, Children and Young People facilities and assets, including construction and planning, alarm systems, asbestos management, fire safety, sewerage and boundary fences.`
                }, 
                {
                    "Name":"Wellbeing, Behaviour, and Safety", 
                    "Description": `Health and wellbeing of students, including bullying, behaviour management and discipline, drug management, infectious diseases, medication, mandatory reporting, and general health and safety.`
                },
                {
                    "Name":"Curriculum and Learning", 
                    "Description": `Student education, including the delivery of the curriculum frameworks including the Early Years Learning Framework, the Australian and Tasmanian curricula, TASC accredited courses, vocational education training (VET), approved learning programs, completion of compulsory education, homework, assessment, moderation and reporting.`
                },
                {
                    "Name":"Parents and School Community", 
                    "Description": `School associations, dress code, religious instruction, chaplains, hostels and grievance resolution.`
                }, 
                {
                    "Name":"​Administration", 
                    "Description": `Administration of the Department for Education, Children and Young People staff and students, including right to information, gifts and benefits, public interest disclosures, personal information protection, and event management.`
                }, 
                {
                    "Name":"Access and Equity", 
                    "Description": `Information on diversity and inclusiveness, including students with disability, gifted students, Lesbian, Gay, Bisexual, Transgender and Intersex (LGBTI) students, English as Additional Language speakers and Aboriginal students.`
                },
                {
                    "Name":"Information and Communications Technology", 
                    "Description": `The use of technology and devices in the Department for Education, Children and Young People, including conditions of use of ICT, web filtering, social media, Bring Your Own Technology and records management.`
                },
                {
                    "Name":"Human Resources and Finance", 
                    "Description": `Department for Education, Children and Young People functions, and resources for staff including employment and selection guidelines, salary, staff grievance resolution, communications, work health and safety (WHS), corporate cards, expenses, professional learning and qualifications.`
                },
                
            ],
            viewChanged:false
        };
    }
        componentDidUpdate(prevProp:any, prevState:any){
       
            console.log('componentDidUpdate');
            
            if(prevState.activeView !== this.state.activeView)
            {
                console.log('View Changed');
    
                if(this.state.activeView === 'AZ')
                {
                    console.log('setting a-z view state');
    
                    this.setState({
                        viewChanged:true,
                        activeView:'AZ',
                        //source:'DoE Forms',
                        activeBtn:"VIEW A-Z",
                        //header:"Displaying Forms Starting with 'A'",
                        searchFilter:'A',
                    }); 
                }
                else if(this.state.activeView === 'PARENT_SEARCH')
                {
                    this.setState({
                        viewChanged:true,
                        activeView:'PARENT_SEARCH',
                        //source:'DoE Forms',
                        activeBtn:null,
                        header:`Displaying Results for '${this.state.searchFilter}'`,
                        searchFilter:this.state.searchFilter,
                    }); 
                }
            }
        }    

        render(){

            console.log(`RENDER::ACTIVE_VIEW::${this.state.activeView}`);
    
            let content = null;
    
            switch(this.state.activeView) { 
                
                case 'TOPIC_SELECTED': { 
    
                    content = <DocumentSearch 
                    source = 'DoE Form'
                    searchView={this.state.activeView}
                    searchFilter={this.state.searchFilter}
                    onSearchResults={(searchResultCount:string) => {this.OnSearchResults(searchResultCount)}}
                    />
                   break; 
                } 
                case 'AZ': {
                    
                    content = <DocumentSearch 
                    source = 'DoE Form'
                    searchView={this.state.activeView}
                    searchFilter={this.state.searchFilter}
                    onLetterChange={(letter:string)=>{this.handleLetterChange(letter)}}
                    onSearchResults={(searchResultCount:string) => {this.OnSearchResults(searchResultCount)}}
                    />                        
                    break; 
                }
                case 'PARENT_SEARCH': {
                    
                    content = <DocumentSearch 
                    source = 'DoE Form'
                    searchView={this.state.activeView}
                    searchFilter={this.state.searchFilter}
                    onSearchResults={(searchResultCount:string) => {this.OnSearchResults(searchResultCount)}}
                    />                        
                    break; 
                }
                case 'TOPICS': { 
    
                    content = <Topics topics={this.state.formTopics} onTopicSelected={(topic:string) => {this.handleTopicSelected(topic)}}/>
                    break; 
                }
                case'HOME':{
                    return <Redirect to='/'/>;
                }
             } 
    
            return(
                <Stack tokens={stackTokens}>
                    <SearchNavBox onSearch={(searchTerm:string) => {this.handleParentSearchInit(searchTerm)}}/>              
                            
                    <Container>
                        <ViewToggle 
                            activeBtn={this.state.activeBtn}
                            header={this.state.header}
                            onViewChange={(view:string) => {this.handleViewChange(view)}}
                            showBtnToolbar={true}
                        toolbarButtons={[
                            {"Name":"Home", "Id":"HOME"},
                            {"Name":"View by topic","Visible":false, "Id":"VIEW BY TOPIC"},
                            {"Name":"View A-Z","Visible":false, "Id":"VIEW A-Z"},
                        ]}/>   
                            {content}
                    </Container>
                </Stack>
            );
        }
        
        private OnSearchResults(results:any){
        
            if(this.state.activeView === 'AZ')
            {
                this.setState({  
                    header:`Displaying ${results.count} ${DocumentTypeName} Starting with ${results.searchTerm}`,         
                })         
            }
            else if(this.state.activeView === 'TOPIC_SELECTED')
            {
                this.setState({  
                    header:`Displaying ${results.count} ${DocumentTypeName} for ${results.searchTerm}`,         
                })
            }
            else if(this.state.activeView === 'PARENT_SEARCH')
            {
                this.setState({  
                    header:`Displaying ${results.count} ${DocumentTypeName} matching ${results.searchTerm}`,         
                }) 
            }
            
        }        

    handleParentSearchInit(searchTerm:string){
        console.log(`Forms::handleParentSearchInit::triggered::${searchTerm}`);

        this.setState({
            searchFilter:searchTerm,
            header:`Searching for '${searchTerm}...'`,
            activeView:'PARENT_SEARCH',
            activeBtn:null
        });
    }

    handleTopicSelected(topic:string){
        console.log(`Forms::handleTopicSelected::triggered::${topic}`);

        this.setState({
            searchFilter:topic,
            header:`Searching for ${topic} ${DocumentTypeName}...`,
            activeView:'TOPIC_SELECTED',
            activeBtn:null
        });
    }

    handleLetterChange(letter:string){
        console.log(`Forms::handleLetterChange::triggered::${letter}`);
        
        this.setState({header:`Searching ${DocumentTypeName}...`});
    }
    
    handleViewChange(view:string){
        console.log(`Forms::handleViewChange::triggered::${view}`);

        if(view === 'VIEW A-Z')
        {
            this.setState({
                viewChanged:true,
                activeView:'AZ',
                source:'DoE Form',
                activeBtn:"VIEW A-Z",
                //header:"Displaying Forms Starting with 'A'",
                searchFilter:'A',
            });            
        }
        else if(view === 'VIEW BY TOPIC')
        {
            this.setState({
                viewChanged:true,
                activeView:'TOPICS',
                source:'DoE Form',
                activeBtn:"VIEW BY TOPIC",
                header:"Department for Education, Children and Young People Forms Library"
            }); 
        }
        else if(view === 'HOME')
        {
            this.setState({
                viewChanged:true,
                activeView:'HOME',
                activeBtn:"HOME",
                header:`Department for Education, Children and Young People Public Library`
            });  
        }
    }


}