import React from 'react';

import { Stack, IStackTokens } from 'office-ui-fabric-react/lib/Stack';
import sampleImg  from './../../assets/Home-Page_Policies-and-Procedures-140px.png';
import { Redirect } from "react-router-dom";

import { Grid, Item } from 'semantic-ui-react'

import css from './../../styles/Home.module.scss';

export class Topics extends React.Component<any,any> {
    
    constructor(props:any) {
        super(props);

        this.state={
            activeView:"Topics",        
        }
    }

    componentDidUpdate(prevProps:any, prevState:any){
        
        if(prevState.activeView !== this.state.activeView)
        {
            this.setState({redirect:true});
        }
        
    }

    render(){
        const stackTokens: IStackTokens = { childrenGap: 20 };

        if(this.state.redirect)
        { 
            return ( 
                <Redirect to={{
                    pathname: "/search",
                    state:{
                        azViewLetter: 'A', 
                        isPolicy:true,
                        isAZView:true
                    }
                }}/>
            ); 
        }
        else
        {
            return(
                <Stack tokens={stackTokens}>
                    <Grid columns={3} divided>                
                        <Grid.Row>
                            {this.props.topics.map((topic: any, i: number) => 
                                <Grid.Column className={css.column} key={i}>
                                    <Item.Group>
                                    <Item className={css.item} >
                                        {/* <Item.Image size='tiny' src={sampleImg}  /> */}
                                        <Item.Content>
                                            <Item.Header className={css.header} >
                                                <h3><a tabIndex={0} onClick={(e:React.MouseEvent)=>{this.handleTopicSelected(e)}}>{topic.Name} </a></h3>
                                            </Item.Header>
                                            <Item.Description className={css.description}>
                                            <p>{topic.Description}</p>
                                            </Item.Description>
                                        </Item.Content>
                                    </Item>
                                    </Item.Group>
                                </Grid.Column>
                            )}
                        </Grid.Row>
                    </Grid>
                </Stack>
            );
        }

        
    }

    handleTopicSelected(e:React.MouseEvent){
        console.log(`Topics::handleTopicSelected::triggered::${e.currentTarget.innerHTML}`);
        this.props.onTopicSelected(e.currentTarget.innerHTML);
    }

    handleViewChange(view:string){
        console.log(`Topics::handleViewChange::triggered::${view}`);

        if(view === 'VIEW A-Z')
        {
            console.log('redirecting to /search...');

            this.setState({
                viewChanged:true,
                activeView:'AZ'
            });

            
        }
    }


}