import React from 'react';

import { Shimmer } from 'office-ui-fabric-react/lib/Shimmer';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';

import {ButtonToolbar, Button, Container, Card} from 'react-bootstrap';

import base64url, {Base64Url} from 'base64url';

import {SearchResultItem} from './SearchResultItem';

import {Item} from 'semantic-ui-react';

import pdf from './../assets/pdf.svg';

import dateformat from 'dateformat';

const wrapperClass = mergeStyles({
    padding: 2,
    selectors: {
      '& > .ms-Shimmer-container': {
        margin: '10px 0'
      }
    }
  });

export class DocumentSearch extends React.Component<any, any>{
    
    constructor(props:any)
    {
        super(props);
        
        this.state = { 
            isPolicy:false,
            isForm:false,
            searchScope:"All",
            results:[],
            loading:true,
            azViewLetter:null,
            topic:null,
            isTopicView:false,
            isAZView:false,
            alphabetLetters: ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
            parentSearchTerm:null,
            hasError:false
        };
    }

    componentDidCatch(error:Error, info:any) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        console.log(error, info);
      }

    componentDidMount(){

        if(this.props.searchView === 'TOPIC_SELECTED'){
            this.getDocumentsByTopic(this.props.searchFilter);
        }
        else if(this.props.searchView === 'AZ')
        {
            this.props.onLetterChange(`Displaying ${this.props.source} starting with 'A'`);

            this.setState({
                azViewLetter:'A',
                //topic:`Displaying ${this.props.source} starting with 'A'`,
                loading:true});
        }
        else if(this.props.searchView === 'PARENT_SEARCH')
        {
            this.getDocumentsBySearchTerm(this.props.searchFilter);
        }
    }

    componentDidUpdate(prevProps:any, prevState:any){
        
        if(prevProps.searchView !== this.props.searchView && this.props.searchView === "AZ")
        {
            this.setState({
                azViewLetter:'A',
                header:`Displaying ${this.props.source} starting with 'A'`,
                loading:true}); 
        }
        else if((prevProps.searchView !== this.props.searchView && this.props.searchView === "PARENT_SEARCH")
        ||
        ( prevProps.searchFilter !== this.props.searchFilter  && this.props.searchView === "PARENT_SEARCH")){
            
            this.setState({
                header:`Displaying Results for '${this.props.searchFilter}'`,
                parentSearchTerm:this.props.searchFilter,
                loading:true}); 
        }
        else if(prevState.azViewLetter !== this.state.azViewLetter)
        {
            this.getDocumentsAlphabetically(this.state.azViewLetter); 
        }
        else if(prevState.parentSearchTerm !== this.state.parentSearchTerm)
        {
            this.getDocumentsBySearchTerm(this.state.parentSearchTerm); 
        }     
    }
    
    render(){

        let contents = this.state.loading
        ? <Fabric className={wrapperClass} tabIndex={-1}>
              <Shimmer />
              <Shimmer width="75%" />
              <Shimmer width="50%"  />
              <Shimmer style={{marginTop:20}}/>
              <Shimmer width="75%" />
              <Shimmer width="50%" />
          </Fabric>
        : this.renderResults(this.state.results)     

      return (
          <Container>
              {
                this.props.searchView === 'AZ' ?                     
                <ButtonToolbar style={{justifyContent:'center', marginBottom:20}}>
                    {this.state.alphabetLetters.map((l:string) =>
                        <Button style={{marginLeft:5}} key={l}  
                            tabIndex={0}
                            role='button'
                            variant="doe" 
                            active={(this.state.azViewLetter === l)} 
                            onClick={(e:React.MouseEvent) => {this.handleLetterChange(e)}}>
                            {l}
                        </Button>
                      )}    
                  </ButtonToolbar> 
                  : null
              }                
              <div>
                {contents}
              </div>
          </Container>            
      );    
    }

    renderResults(results:any){

        console.log(results);
        
        if(results && !this.state.hasError)
        {
            if(results.length > 0)
            {
                return(
                    <div>
                        
                        <Item.Group>
                        {
                            results.map(({document, highlights}:{document:any, highlights:any}) => {

                                    let documentFileType:string = document.spO_File_Type?document.spO_File_Type:'';
                                    let documentFileSize:string = document.metadata_storage_size ? (Math.round(Number(document.metadata_storage_size)/1000)).toString():'0'; 
                                    let description:any = document.spO_Purpose1 || "";
                                    let title:string = `${document.spO_Title} (${documentFileType.toUpperCase()}, ${documentFileSize} KB)`;
                                    let publicUrl:string = base64url.decode(document.metadata_storage_path).replace('publicdoccentrestorage.blob.core.windows.net','publicdocumentcentre.education.tas.gov.au');

                                    if(highlights)
                                    {
                                        if(typeof(highlights.content) === 'object')
                                        {
                                            description = highlights.content.join(' ');     
                                        }

                                        if(highlights.SPO_Title)
                                        {
                                            title = `${highlights.SPO_Title} (${documentFileType.toUpperCase()}, ${documentFileSize} KB)` || `${document.spO_Title} (${documentFileType.toUpperCase()}, ${documentFileSize} KB)`;
                                        }
                                        

                                    }

                                   return <SearchResultItem
                                        documentTile={title}
                                        documentId = {document.spO_DocId}
                                        documentPath={publicUrl}
                                        documentDescription={ description.replace(/(\r\n|\n|\r)/gm, "")}
                                        documentLastReviewDate={document.spO_Last_Review?document.spO_Last_Review.split(' ')[0]:''}
                                        documentPublicUrl={publicUrl}
                                        documentIcon = {pdf}
                                        documentType ={document.spO_File_Type?document.spO_File_Type:''}
                                        documentSize={document.metadata_storage_size ? (Math.round(Number(document.metadata_storage_size)/1000)).toString():'0' }
                                    />
                                }
                            )
                        }                    
                    </Item.Group></div>              
                );   
            }
            else{
                return (
                    <Card style={{marginTop:25}}>
                        <Card.Body>No Results Found</Card.Body>
                    </Card>
                ) 
            } 
        }
        else
        {
            return (
                <Card style={{marginTop:25}}>
                  <Card.Body>No Results Found</Card.Body>
                </Card>
            ) 
        }
    }

    handleLetterChange(e:React.MouseEvent){

        this.props.onLetterChange(`Displaying ${this.props.source} starting with '${e.currentTarget.innerHTML}'`);

        this.setState({
            azViewLetter:e.currentTarget.innerHTML,
            topic:`Displaying ${this.props.source} starting with '${e.currentTarget.innerHTML}'`,
            loading:true});
    }
    async getDocumentsByTopic(topic:String){

        const response = await fetch('document/GetSearchResultByTopic',{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                searchText: `search="${topic}"`,
                documentSource: `${this.props.source}`
            })
          })
          .then(res => res.text())
          .then((text) => text.length ? JSON.parse(text) : {})
          .then((json) => 
          
            {
                this.props.onSearchResults(
                
                    {
                        "count":json.results.length,
                        "searchTerm":topic
                    }
                );
                this.setState({ 
                    results: json.results, 
                    loading: false, 
                    topic: topic
                })
            }
            )
          .catch(error => { 
                console.log('An error occured ', error);
                this.setState({ results: {}, loading: false});
          }); 
    }
    async getDocumentsAlphabetically(letter:string)
    {
        const response = await fetch('document/GetSearchResultsAlpahbetically',
        {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                searchText: `search="${letter}"&$count=true`,
                documentSource: `${this.props.source}`
            })
          })
          .then(res => res.text())
          .then((text) => text.length ? JSON.parse(text) : {})
          .then((json) => {
            
            this.props.onSearchResults(
                
                    {
                        "count":json.results.length,
                        "searchTerm":letter
                    }
                );
            this.setState({ 
                    results: json.results, 
                    loading: false
                })
            }
            )
          .catch(error => { 
                console.log('An error occured ', error);

                this.setState({ results: null, 
                    loading: false
                });
          });
    }
    async getDocumentsBySearchTerm(searchTerm:string){
        console.log('getPoliciesBySearchTerm');

        const response = await fetch('document/GetSearchResultBySearchTerm',{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                searchText: `${searchTerm}`,
                documentSource: `${this.props.source}`
            })
          })
          .then(res => {
              console.log(res);
              return res.text();
            })
          .then((text) => text.length ? JSON.parse(text) : {})
          .then((json) =>          
            
          {
            if(json.results)
            {
                this.props.onSearchResults(
                
                    {
                        "count":json.results.length||0,
                        "searchTerm":searchTerm
                    }
                );
            }
            
            this.setState({ 
                results: json.results, 
                loading: false, 
                });
            } 
            )
          .catch(error => { 
                console.log('An error occured ', error);
                this.setState({ results: {}, loading: false});
          }); 
    }
}