import * as React from 'react';
import {ButtonToolbar, Button} from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import style from './../styles/PoliciesToggle.module.scss';

export class ViewToggle extends React.Component<any,any>{
    constructor(props:any){
        super(props);

        this.state = {
           
        }
    }

    render(){
        
        

        return(
            <div style={{marginTop:40, marginBottom:40}}
                className={style.toggleWrapper}>
                <div><h1>{this.props.header}</h1></div>              
                <ButtonToolbar className={style.toggleButtons}>
                    {this.props.toolbarButtons.map((btn: any, i: number) => 
                        <Button tabIndex={0} key={i} role='button'
                            
                            id={btn.Id}
                            variant="doe"
                            active={(this.props.activeBtn === btn.Id)} 
                            onClick={(e:React.MouseEvent) => {
                                this.handleViewChnage(e)
                                
                            }}>
                            {btn.Name}
                        </Button>      
                    )}
                </ButtonToolbar>
            </div>
        );
    }

    handleViewChnage(e:React.MouseEvent){        
        this.props.onViewChange(e.currentTarget.getAttribute('id'));
        console.log(`ViewToggle::handleViewChnage::triggered ${e.currentTarget.getAttribute('id')}`);
    }

    onHomeClicked = () => {
        console.log('Home Button Clicked')
        return <Redirect  to="/" /> 
     }

}