import React, { Component } from 'react';

import Header from './Header';
import ImgCarousel from './ImgCarousel';



export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div>
        <Header />
        {/* <ImgCarousel />        */}
        {this.props.children}
        
      </div>
    );
  }
}
