import * as React from 'react';
import {Container} from 'react-bootstrap';

import css from './../styles/footer.module.scss';
import logo from './../assets/tas-footer-logo.svg';

export class Footer extends React.Component<any,any>{

    constructor(props:any){
        super(props);


    }

    render(){

        return(
            <div className={css.footer}>
                <Container>
                <div className="row">
                    <div className="col-md-10 col-sm-9" style={{paddingLeft:0}}>
                        <ul id="menu-footer" className="menu clearfix">
                            <li id="menu-item-20" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item menu-item-20"><a href="https://www.decyp.tas.gov.au/" aria-current="page">Home</a></li>
                            <li id="menu-item-9411" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9411"><a href="https://www.decyp.tas.gov.au/about-us/">About us</a></li>
                            <li id="menu-item-9412" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-9412"><a href="https://www.decyp.tas.gov.au/about-us/contacts/">Contact us</a></li>
                            <li id="menu-item-703" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-703"><a href="https://www.decyp.tas.gov.au/about-us/legislation/rti/">Right to Information and Routine Disclosure</a></li>
                            <li id="menu-item-818" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-818"><a href="https://www.decyp.tas.gov.au/about-us/legislation/accessibility/">Accessibility</a></li>
                            <li id="menu-item-704" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-704"><a href="https://www.decyp.tas.gov.au/home/terms-of-service/">Terms of Service</a></li>
                            <li id="menu-item-19" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-19"><a href="http://www.service.tas.gov.au/">Service Tasmania</a></li>
                        </ul>   
                        <div className={css.copyright}>
                            <p>You are directed to the&nbsp;
                                <a href="https://www.decyp.tas.gov.au/about-us/copyright-and-disclaimer/" target="_blank" rel="noopener">Disclaimer and Copyright Notice</a> 
                                &nbsp;and a&nbsp;
                                <a href="http://www.tas.gov.au/stds/pip.htm" target="_blank" rel="noopener">Personal Information Protection Statement</a> 
                                &nbsp;governing the information provided.
                            </p>
                        </div>                 
                    </div>
                    <div className={`col-md-2 col-sm-3 ${css.logo}`}>
                        <a href="http://www.tas.gov.au">
                            <img src={logo} alt="Tasmanian Government Logo" width="100" height="86"/>
                        </a>
                    </div>
                </div>
                </Container>
            </div>
        );
    }

}