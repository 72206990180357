import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import {Home} from './components/Home';
import {Forms} from './components/Forms';
import {Policies} from './components/Policies';
import {SchoolAssocCons} from './components/SchoolAssocCons';
import {SchoolIntakeAreas} from './components/SchoolIntakeAreas';
import { Footer } from './components/Footer';
import { initializeIcons } from '@uifabric/icons';
// get our fontawesome imports
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



//import './styles/doe.cdn.scss'
import './custom.css'

initializeIcons();


export default class App extends Component {
  static displayName = App.name;

 

  render () {
    return (
      <div className='Site'>
      <div className='Site-content'>
      <Layout>
        <Route exact path='/' component={Home} />
        <Route exact path='/Forms' component={Forms} />
        <Route exact path='/Policies' component={Policies} />
        <Route exact path='/SchoolAssocCons' component={SchoolAssocCons} />
        <Route exact path='/SchoolIntakeAreas' component={SchoolIntakeAreas} />
      </Layout>
      </div>
      <Footer/>
      </div>
    );
  }
}
