import React, { Component } from 'react';
import { Button, Icon, Image, Item, Label } from 'semantic-ui-react'
import CopyToClipboard from 'react-copy-to-clipboard';

import 'semantic-ui-css/semantic.min.css'
import css from './../styles/Home.module.scss';

import ToastNotification from './ToastNotification';
//import { ToastContainer, toast } from 'react-toastify';

//import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ReactHtmlParser from 'react-html-parser';
import { optionProperties } from 'office-ui-fabric-react';

export interface ISearchResultItemProps {
    documentTile:string,
    documentId:string,
    documentLastReviewDate: string,
    documentPath:string,
    documentPublicUrl:string,
    documentDescription:string,
    documentIcon:string,
    documentType:string,
    documentSize:string
  }
  //const {addToast} = useToasts();
  // const notify = () => {
  //   //toast("Default Notification !");

  //   toast.success("Document Link Copied Successfully.", {
  //     position:"top-right"
  //   });
  // };

export class SearchResultItem extends React.Component<ISearchResultItemProps,any> {

    constructor(props:any) {
        super(props);
    }

    render(){      

        return(
            <Item className={css.item}>
            {/* <a 
                tabIndex={-1}
                href={this.props.documentPath.endsWith('5')? this.props.documentPath.substr(0, (this.props.documentPath.length -1)) : this.props.documentPath} 
               target="_blank" title={this.props.documentTile}>
                {
                  this.props.documentType === 'pdf'
                  ? <FontAwesomeIcon icon={['far', 'file-pdf']} size="3x" title='pdf'/>  
                  : this.props.documentType === 'docx' || this.props.documentType === 'doc'
                    ? <FontAwesomeIcon icon={['far', 'file-word']} size="3x" title='doc'/>  
                    : <FontAwesomeIcon icon={['far', 'file']} size="3x" title='file'/> 
                }
            </a> */}
            <Item.Content>
                <Item.Header className={css.header}>
                    <a 
                        tabIndex={0}
                        title={this.props.documentTile + " (" + this.props.documentType + ", " + this.props.documentSize +"kb)" }
                        href={this.props.documentPath.endsWith('5')? this.props.documentPath.substr(0, (this.props.documentPath.length -1)) : this.props.documentPath} 
                        target="_blank"><h3>{ReactHtmlParser(this.props.documentTile)}</h3>                      
                    </a>
                </Item.Header>
              <Item.Description className={css.description}>{ReactHtmlParser(this.props.documentDescription) }</Item.Description>
              {/* <Item.Meta>
                <span className='cinema'>{'Last review: '+ this.props.documentLastReviewDate}</span>
              </Item.Meta> */}
              {/* <Item.Meta>
                <span className='cinema'>{'Document: ' + this.props.documentType +' '+ this.props.documentSize +' kb'}</span>
              </Item.Meta> */}
              <Item.Extra className={css.copydoclink}>
                <CopyToClipboard text={`https://documentcentre.education.tas.gov.au/_layouts/15/DocIdRedir.aspx?ID=${this.props.documentId}`} onCopy={() => {return <ToastNotification/>} }>
                <a><FontAwesomeIcon icon={['fas', 'link']} size="1x" title='link'/> Copy document link</a>
                    {/* <Button floated='left' tabIndex={0} alt='copy document link' role='button'>
                        
                    </Button> */}                
                </CopyToClipboard>
                
              </Item.Extra>
            </Item.Content>
          </Item>
        )
    }  
}