import React from 'react';
import SearchNavBox from './SearchNavBox';
import {ViewToggle} from './ViewToggle';
import { Topics } from './Forms/Topics';
//import { SearchResult } from './SearchResult';
import {Container} from 'react-bootstrap';
import { Stack, IStackTokens } from 'office-ui-fabric-react/lib/Stack';
import { Link, Redirect } from 'react-router-dom';
import {DocumentSearch} from './DocumentSearch';


const stackTokens: IStackTokens = { childrenGap: 20 };
const DocumentTypeName: string = 'School Association Constitutions';
const SPOContentTypeName: string = 'DoE School Constitutions';

export class SchoolAssocCons extends React.Component<any, any>{

    constructor(props:any){
        super(props);

        this.state = {
            activeView:"AZ",
            activeBtn:"VIEW A-Z",
            header:`${DocumentTypeName} Library`,
            viewChanged:false
        };
    }
        componentDidUpdate(prevProp:any, prevState:any){
       
            console.log('componentDidUpdate');
            
            if(prevState.activeView !== this.state.activeView)
            {
                console.log('View Changed');
    
                if(this.state.activeView === 'AZ')
                {
                    console.log('setting a-z view state');
    
                    this.setState({
                        viewChanged:true,
                        activeView:'AZ',
                        activeBtn:"VIEW A-Z",
                        searchFilter:'A',
                    }); 
                }
                else if(this.state.activeView === 'PARENT_SEARCH')
                {
                    this.setState({
                        viewChanged:true,
                        activeView:'PARENT_SEARCH',
                        activeBtn:null,
                        searchFilter:this.state.searchFilter,
                    }); 
                }
            }
        }    

        render(){

            console.log(`RENDER::ACTIVE_VIEW::${this.state.activeView}`);
    
            let content = null;
    
            switch(this.state.activeView) { 
                
                case 'TOPIC_SELECTED': { 
    
                    content = <DocumentSearch 
                    source = {SPOContentTypeName}
                    searchView={this.state.activeView}
                    searchFilter={this.state.searchFilter}
                    onSearchResults={(searchResultCount:string) => {this.OnSearchResults(searchResultCount)}}
                    />
                   break; 
                } 
                case 'AZ': {
                    
                    content = <DocumentSearch 
                    source = {SPOContentTypeName}
                    searchView={this.state.activeView}
                    searchFilter={this.state.searchFilter}
                    onLetterChange={(letter:string)=>{this.handleLetterChange(letter)}}
                    onSearchResults={(searchResultCount:string) => {this.OnSearchResults(searchResultCount)}}
                    />                        
                    break; 
                }
                case 'PARENT_SEARCH': {
                    
                    content = <DocumentSearch 
                    source = {SPOContentTypeName}
                    searchView={this.state.activeView}
                    searchFilter={this.state.searchFilter}
                    onSearchResults={(searchResultCount:string) => {this.OnSearchResults(searchResultCount)}}
                    />                        
                    break; 
                }
                case 'TOPICS': { 
    
                    content = <Topics topics={this.state.formTopics} onTopicSelected={(topic:string) => {this.handleTopicSelected(topic)}}/>
                    break; 
                }
                case'HOME':{
                    return <Redirect to='/'/>;
                }
             } 
    
            return(
                <Stack tokens={stackTokens}>
                    <SearchNavBox onSearch={(searchTerm:string) => {this.handleParentSearchInit(searchTerm)}}/>              
                            
                    <Container>
                        <ViewToggle 
                            activeBtn={this.state.activeBtn}
                            header={this.state.header}
                            onViewChange={(view:string) => {this.handleViewChange(view)}}
                            toolbarButtons={[
                                {"Name":"Home", "Id":"HOME"},
                                // {"Name":"View by topic","Visible":false, "Id":"VIEW BY TOPIC"},
                                {"Name":"View A-Z","Visible":false, "Id":"VIEW A-Z"},
                            ]}/>   
                            {content}
                    </Container>
                </Stack>
            );
        }
        

    handleParentSearchInit(searchTerm:string){
        console.log(`${DocumentTypeName}::handleParentSearchInit::triggered::${searchTerm}`);

        this.setState({
            searchFilter:searchTerm,
            header:`Searching for '${searchTerm}...'`,
            activeView:'PARENT_SEARCH',
            activeBtn:null
        });
    }
    
    private OnSearchResults(results:any){
        
        if(this.state.activeView === 'AZ')
        {
            this.setState({  
                header:`Displaying ${results.count} ${DocumentTypeName} Starting with ${results.searchTerm}`,         
            })         
        }
        else if(this.state.activeView === 'TOPIC_SELECTED')
        {
            this.setState({  
                header:`Displaying ${results.count} ${DocumentTypeName} for ${results.searchTerm}`,         
            })
        }
        else if(this.state.activeView === 'PARENT_SEARCH')
        {
            this.setState({  
                header:`Displaying ${results.count} ${DocumentTypeName} matching ${results.searchTerm}`,         
            }) 
        }
        
    }

    handleTopicSelected(topic:string){
        console.log(`${DocumentTypeName}::handleTopicSelected::triggered::${topic}`);

        this.setState({
            searchFilter:topic,
            header:`Searching for ${topic} ${DocumentTypeName}...`,
            activeView:'TOPIC_SELECTED',
            activeBtn:null
        });
    }

    handleLetterChange(letter:string){
        console.log(`${DocumentTypeName}::handleLetterChange::triggered::${letter}`);
        
        this.setState({header:`Searching ${DocumentTypeName}...`});
    }
    
    handleViewChange(view:string){
        console.log(`${DocumentTypeName}::handleViewChange::triggered::${view}`);

        if(view === 'VIEW A-Z')
        {
            this.setState({
                viewChanged:true,
                activeView:'AZ',
                source:{SPOContentTypeName},
                activeBtn:"VIEW A-Z",
                searchFilter:'A',
            });            
        }
        else if(view === 'VIEW BY TOPIC')
        {
            this.setState({
                viewChanged:true,
                activeView:'TOPICS',
                source:{SPOContentTypeName},
                activeBtn:"VIEW BY TOPIC",
                header:`Department for Education, Children and Young People ${DocumentTypeName} Library`
            }); 
        }
        else if(view === 'HOME')
        {
            this.setState({
                viewChanged:true,
                activeView:'HOME',
                source:{SPOContentTypeName},
                activeBtn:"HOME",
                header:`Department for Education, Children and Young People Public Library`
            });  
        }
    }


}