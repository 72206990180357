import * as React from 'react';
import { SearchBox} from 'office-ui-fabric-react/lib/SearchBox';
import { IButtonProps, IIconProps } from 'office-ui-fabric-react';
import { Redirect } from "react-router-dom";
import styles from './../styles/SearchNav.module.scss';


// const emojiIcon: IIconProps = { iconName: 'Emoji2' };
const customClearBtnProps: IButtonProps = { text:'Search this website', iconProps:{iconName:'Search', title:'Search this website'}};//iconProps:{ iconName: 'Emoji2' }};

// tslint:disable:jsx-no-lambda
export default class SearchNavBox extends React.Component<any, any> {
  
  constructor(props:any){
    super(props);
  
    this.state = { 
      toSearchResults: false, 
      searchTerm:"" 
    };
  }

  public render(){

    
    let inputText:string = '';

    return (

      <div className={styles.searchB}>
        <div className={styles.header}>Document Library</div>      
        <div  className = {styles.searchBox}>
          <SearchBox className={styles.rootSb}
            placeholder="Search documents"
            labelText="Search this website"
            ariaLabel="Search this website"
            onSearch={newValue => this.props.onSearch(newValue)}
            // onBlur={(e) => e.currentTarget.innerHTML = ''}
            onChange={(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => { inputText = newValue||'' }}            
            onClear={(e)=> 
              {
                e.preventDefault();
                
                if(inputText.length > 0)
                {
                  this.props.onSearch(inputText)
                }
                
              }
            }
            clearButtonProps={customClearBtnProps}
          />
        </div>
      </div>
    );

  }
}