// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
//import { fafilepdf } from '@fortawesome/free-light-svg-icons';
import { faFilePdf, faFileWord, faFileExcel, faFilePowerpoint, faFile } from '@fortawesome/free-regular-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';

library.add(
    faFilePdf,
    faFileExcel,
    faFileWord,
    faFilePowerpoint,
    faFile,
    faLink
  // more icons go here
);