import * as React from 'react';
import {Container, Navbar, Nav} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import styles from './../styles/Header.module.scss';
import learnersFirstWeb from './../assets/learners-first-web.svg';
import logo from './../assets/tas-gov_bw.svg';
import logoText from './../assets/decyp-logo-text.svg';

export default class Header extends React.Component<any, any>{
    
    public render(): JSX.Element {
        return (
            <div id="header" className={styles.header}>
                <Navbar expand="lg" className={styles.navBarOverride} >
                    <Container className={styles.containerOverride}>
                        <div className={styles.learnerTab}>
                            {/*<a href="https://www.decyp.tas.gov.au">*/}
                            {/*    <img src={learnersFirstWeb} alt="Department for Education, Children and Young People home page"/>*/}
                            {/*</a>*/}
                        </div>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" color="white" className={styles.navbarToggleOverride} />
                        <Navbar.Collapse id="basic-navbar-nav">
                        <Navbar.Brand>
                            <div className={styles.logo}>
                                <Link to="/">
                                { /*<a href={styles.logo}> */}
                                    <img src={logo} alt="Department for Education, Children and Young People home page" height="55" width="70"/>
                                    <img src={logoText} alt="The Department for Education, Children and Young People Tasmania" height="48"/>
                                {/* </a> */}
                                </Link>
                            </div>
                        </Navbar.Brand>

                            <Nav className={'col-md-9 col-sm-8 ' + styles.navBarNavOverride}>
                                {/* <Nav.Link className={styles.navLinkOverride} href="#home">Staff Intranet</Nav.Link>
                                <Nav.Link className={styles.navLinkOverride} href="#link">Public Website</Nav.Link>
                                <Nav.Link className={styles.navLinkOverride} href="#link">Templates (New Staff Intranet)</Nav.Link> */}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        );
    }
} 